/* Base styles for the avatar */
.avatar {
  position: fixed;
  overflow: hidden;
  z-index: -2;
  opacity: 0;
}

.avatar.about {
  height: 89vh;
  bottom: -8vh;
  left: 50%;
  transform: translateX(-50%);
}

.avatar.skills {
  height: 190vh;
  bottom: -110vh;
  left: -1.5rem;
  transform: translateX(0%) scaleX(-1);
}

/* Styles for the avatar image */
.avatar.fade-in {
  opacity: 1;
}

/* Responsive styles for screens with max-width of 1024px */
@media (max-width: 1024px) {
  .avatar.skills {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
  .avatar.about {
    z-index: -5;
    height: 95vh;
    bottom: -15vh;
  }
}
