.project-menu {
  width: 45rem;
  position: fixed;
  display: flex;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.project-items-container {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 40%;
  margin-top: 5rem;
  align-items: center;
}

/* arrow button */
.arrow-button-container {
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.arrow-button-container.visible {
  visibility: visible;
}

.arrow-button {
  cursor: pointer;
  color: wheat;
}

.project-item {
  height: 3.75rem;
  width: 90%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.project-item.activeProject .title {
  font-size: 1.5rem;
}

.activeProject {
  border-radius: var(--radius-left);
  cursor: default;
  background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
  width: 100%;
  font-size: 1.2rem;
}

.project-sub-container {
  width: 60%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}
.project-sub-container h3 {
  position: relative;
  margin: 0;
  font-size: 1.5rem;
}
.project-sub-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem 3rem 0.5rem 0.5rem;
  opacity: 0.9;
}

.image-container {
  position: relative;
  overflow: hidden;
}

/* Hover Description*/
.hover-description {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background-color: rgba(0, 0, 0, 0.85);
  color: var(--wheat);
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: opacity 1s;
  border-radius: 0.4rem 3rem 0.4rem 0.4rem;
  box-shadow: none;
  opacity: 0;
  margin: 0;
}

.image-container:hover .hover-description {
  opacity: 1;
}

/* Project page hover description */
.icon-with-title h3 {
  font-size: 0.8rem;
  color: var(--wheat);
  text-align: center;
}
.hover-description i {
  font-size: 3rem;
}

.tech-stack {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  gap: 10px;
}

.icon-with-title i {
  font-size: 2rem;
  display: block;
  margin-bottom: 0.5rem;
}

.project-sub-container p {
  font-size: 1rem;
}

.link-container a {
  color: var(--white);
  text-decoration: none;
  margin-top: 1rem;
  width: 50%;
  height: 2.75rem;
  text-align: center;
}

.link-container a:hover {
  background-image: linear-gradient(90deg, #ffffff00, #f5deb3c0 50%, #ffffff00);
}

.link-container {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 1024px) {
  .icon-with-title i {
    font-size: 1rem;
  }
  .icon-with-title h3 {
    font-size: 0.5rem;
  }
  .project-menu {
    width: 28rem;
    top: 20%;
    transform: translateX(-50%);
  }
  .project-menu p {
    font-size: 0.7rem;
  }
  .project-item {
    height: 2rem;
    width: 100%;
  }
  .project-item.activeProject .title {
    font-size: 0.9rem;
  }
  .title {
    font-size: 0.7rem;
  }
  .project-sub-container h3 {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .project-menu {
    width: 28rem;
    flex-direction: column;
    align-items: center;
  }
  .project-menu p {
    font-size: 0.65rem;
  }
  .project-sub-container {
    width: 12rem;
  }
  .project-sub-container img {
    width: 12rem;
  }
  .link-container a {
    font-size: 0.6rem;
    margin-top: 0;
    width: 50%;
    height: 2rem;
  }
  .project-items-container {
    margin-top: 0;
  }
  .project-item.activeProject .title {
    font-size: 0.8rem;
  }
  .title {
    font-size: 0.6rem;
  }
  .icon-with-title h3 {
    font-size: 0.4rem;
  }
}
