/* Background video styling */
#bg {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -5;
}
/* Additional styling for the background video */
.bg-video {
  scale: 1.05;
}

/* Overlay to add shadow effect on the background */
.shadow-overlay {
  position: fixed;
  z-index: -4;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Hides default media controls on the video */
#bg::-webkit-media-controls {
  display: none !important;
}

#bg::-webkit-media-controls-enclosure {
  display: none !important;
}

#bg::-webkit-media-controls-play-button {
  display: none !important;
}

/* Media query for screens with a maximum width of 1024px */
@media (max-width: 1024px) {
  .shadow-overlay {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
