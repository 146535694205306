#playerStats {
  width: 14rem;
  height: 4rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 3rem;
  left: 3rem;
}

h1 {
  font-size: var(--font-size-large);
  margin: 0;
}

h2 {
  font-size: var(--font-size-medium);
  margin: 0;
}

#playerStats__lines {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  position: relative;
}

#playerStats__lines__thick {
  background-color: var(--white);
  height: 0.2rem;
  width: 0;
  transition: width 2s ease-out;
  position: absolute;
  left: 0;
}

#playerStats__lines__thin {
  background-color: var(--grey);
  height: 0.2rem;
  width: calc(1rem + 22.5rem);
}

#playerStats a {
  width: var(--icon-size);
}

.icon-container {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.icons {
  width: var(--icon-size);
  border-radius: var(--border-radius);
}

@media (max-width: 768px) {
  #playerStats__lines {
    margin-top: 0.2rem;
  }
  #playerStats__lines__thick {
    height: 0.1rem;
  }
  #playerStats {
    left: 5%;
    width: 7.5rem;
    height: 2.5rem;
    top: 1rem;
  }
  h1 {
    font-size: var(--font-size-small);
  }
  #playerStats h2 {
    font-size: 0.6rem;
  }
  p {
    font-size: 0.65rem;
  }
  #playerStats a {
    width: var(--icon-size-small);
  }
  .icon-container {
    margin-top: 0.2rem;
  }
  .icons {
    width: var(--icon-size-small);
  }
}
