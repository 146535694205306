/* Importing Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

@import "devicon/devicon.min.css";

/* Defining CSS variables (custom properties) for reuse throughout the stylesheet */
:root {
  --purple: #540351;
  --blue: #3627b1;
  --cyan: #25afce;
  --white: #ffffff;
  --wheat: #f5deb3;
  --grey: rgb(211, 211, 211);
  --transparent: #00000000;
  --radius-left: 3rem 0 0 3rem;
  --radius-right: 0 3rem 3rem 0;
  --font-size-large: 1.5rem;
  --font-size-medium: 1rem;
  --font-size-small: 0.8rem;
  --icon-size: 2rem;
  --icon-size-small: 1.5rem;
  --border-radius: 0.5rem;
}

/* General styles for the body */
body {
  font-family: "Russo One", sans-serif;
  color: var(--white);
  -webkit-tap-highlight-color: transparent; /* Removes the highlight color when tapping on an element */
}

/* Removing default text decoration for links */
a {
  text-decoration: none;
}

/* Adding a breathing animation to active items */
.item.active,
.activeSkill,
.activeProject,
.activeHackathon,
.activeCategory {
  animation: breath 3s infinite;
}

/* Hover effect with a gradient and breathing animation */
button:hover,
.home-button:hover,
.link-container a:hover,
.class-sub-container a:hover,
.project-item:hover,
.hackathon-item:hover,
.skill-item:hover,
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-3:not(.active-subheading):hover {
  background-image: linear-gradient(90deg, #ffffff00, #f5deb3c0 50%, #ffffff00);
  animation: breath 2.5s infinite;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes wave {
  0% {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
    background-position: 0 0;
  }
  25% {
    box-shadow: rgba(255, 255, 255, 0.3) 0px 5px 15px;
    background-position: 0 0;
  }
  50% {
    box-shadow: rgba(245, 222, 179, 0.5) 0px 5px 15px;
    background-position: 0 0;
  }
  75% {
    box-shadow: rgba(255, 255, 255, 0.3) 0px 5px 15px;
    background-position: 0 0;
  }
  100% {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
    background-position: 0 0;
  }
}

@keyframes breath {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/* Animation classes for applying the keyframes */
.scale-in {
  animation: scaleIn 10s ease-in forwards;
}

.fade-in {
  animation: fadeIn 2s ease-in;
}

.wave {
  animation: wave 2s infinite;
}

.breath {
  animation: breath 3s infinite;
}

.scale-in-out {
  animation: scaleInOut 2s infinite;
}

/* Responsive styles for smaller screens */
@media (max-width: 1024px) {
  h1 {
    font-size: 0.8rem;
  }
  h2,
  h3,
  p {
    font-size: 0.7rem;
  }
}
