.not-found {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  animation: fadeIn 2s ease-in;

  h1 {
    font-size: 8rem;
    margin-bottom: 1rem;
    animation: breath 5s infinite;
  }

  h2 {
    animation: breath 5s infinite;
  }

  p {
    color: var(--transparent);
  }
}

.home-button {
  margin-top: 1rem;
  width: 10rem;
  color: var(--white);
  background: var(--transparent);
  border-radius: 3rem;
  animation: breath 2.5s infinite;
}
.home-button:hover {
  background: var(--transparent);
}

canvas {
  width: 50%;
  height: 50%;
  position: relative;
  z-index: 3;
}

@keyframes pan-one {
  from {
    translate: -100%;
  }
}

@keyframes pan-two {
  to {
    translate: 100%;
  }
}
